<template>
  <div class="bg-neutral-200 p-6 text-center sm:p-8 md:py-20">
    <div v-if="$slots.icons" class="flex flex-row justify-center">
      <slot name="icons" />
    </div>
    <h5 v-if="$slots.heading" class="display-2xl md:display-3xl mt-8 md:mt-12">
      <slot name="heading" />
    </h5>

    <p v-if="$slots.default" class="para-base mx-auto mb-0 mt-6 max-w-sm">
      <slot />
    </p>
  </div>
</template>
